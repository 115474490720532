<template>
    <div class="headersection"><Banner /></div>
    <div id="nav">
        <router-link :class="activelinks[0]" to="/">Home</router-link> |
        <router-link :class="activelinks[1]" to="/links"
            >coding links</router-link
        >
        |
        <router-link :class="activelinks[2]" to="/apps"
            >apps and modules</router-link
        >
        | <router-link :class="activelinks[3]" to="/about">about</router-link> |
    </div>
    <div class="navbottom"></div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'

export default {
    name: 'Header',
    components: {
        Banner,
    },
    computed: {
        activelinks() {
            var links = ['', '', '', '']
            links[this.$route.tabidx] = 'active'
            return links
        },
    },
}
</script>

<style>
#nav {
    margin: 0.5rem 0 0;
    display: inline-block;
    align-items: center;
}

#nav a {
    font-weight: normal;
    color: #000;
    padding: 0.75rem 0.75rem 1.5rem;
    text-decoration: none;
}

#nav a.active {
    background-color: #d3d0c2;
    border-radius: 0.5rem;
}

.navbottom {
    height: 1rem;
}

.headersection {
    padding: 0.25rem;
}
</style>
