<template>
    <div class="viewport">
        <div v-if="!simple" class="dynamic">
            <router-view
                v-for="panel of panels"
                :key="panel.idx"
                :name="panel.target" />
        </div>
        <div v-else class="simple">
            <router-view name="header" />
            <router-view name="main" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            simple: true,
            panels: [],
            tabsidx: 3,
        }
    },
}
</script>

<style>
html,
body,
.viewport {
    height: 100%;
    margin: 0;
}

img {
    max-width: 100%;
}

img[width] {
    width: auto;
}

img[width][height] {
    height: auto;
}

img[src$='.svg'] {
    width: 100%;
    height: auto;
    max-width: none;
}

.simple {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
}

.home {
    flex-grow: 1;
    padding: 0.75rem 0 0;
    background-color: #d3d0c2;
}

.contentpanel {
    margin: 0 3rem 2rem;
}

.teasercol {
    background-color: #bbb6a1;
    border-radius: 0.5em;
    margin: 0.5rem;
    padding: 1rem;
    width: 100%;
}

.teaserrow {
    width: 100%;
    display: flex;
}

@media only screen and (min-width: 768px) {
    .teaserrow {
        max-width: 72rem;
        margin: auto;
    }
}

@media only screen and (min-width: 1160px) {
    .teasers {
        display: table;
        margin: 0 auto;
    }
}

.placeholder {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #6b6443;
}

.spinners {
    width: 100%;
    display: block;
    text-align: center;
}

.loading {
    width: 100%;
    display: block;
    text-align: center;
}
</style>
