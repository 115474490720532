import { createStore } from 'vuex'

function sanitizedTag(tag) {
    return tag.replace(/[^a-zA-Z0-9]+/g, '')
}

export default createStore({
    state: { user: {}, tags: [], storedlist: [] },
    getters: {
        getTags: (state) => {
            return state.tags
        },
        getList: (state) => {
            return state.storedlist
        },
    },
    mutations: {
        setAttr(state, name) {
            state.user = name
        },
        addTag(state, tag) {
            state.tags.push(sanitizedTag(tag))
        },
        addTags(state, tags) {
            tags.forEach((tag) => state.tags.push(sanitizedTag(tag)))
        },
        replaceTags(state, tags) {
            tags.forEach((tag) => (tag = sanitizedTag(tag)))
            state.tags = [...tags]
        },
        removeTag(state, tagDetail) {
            let idx = tagDetail.idx
            let name = tagDetail.name
            if (state.tags[idx] == name) {
                state.tags.splice(idx, 1)
            }
        },
        storeList(state, list) {
            if (list != null && list.length > 0) {
                state.storedlist = list
            }
        },
    },
    actions: {
        replaceTags: ({ commit }, tags) => {
            commit('replaceTags', tags)
        },
        addTags: ({ commit }, tags) => {
            commit('addTags', tags)
        },
        removeTag: ({ commit }, tagDetail) => {
            if (tagDetail != null) commit('removeTag', tagDetail)
        },
        storeList: ({ commit }, list) => {
            if (list != null && list.length > 0) commit('storeList', list)
        },
    },
    modules: {},
})
