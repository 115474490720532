import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Header from '../views/Header.vue'
import About from '../views/About.vue'
import Links from '../views/Links.vue'
import MyApps from '../views/MyApps.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        components: {
            default: Home,
            main: Home,
            header: Header,
            left: Home,
            right: Home,
            topleft: Home,
            topright: Home,
            bottomleft: Home,
            bottomright: Home,
        },
        tabidx: 0,
    },
    {
        path: '/links',
        name: 'Links',
        components: {
            default: Home,
            main: Links,
            header: Header,
            left: Home,
            right: Home,
            topleft: Home,
            topright: Home,
            bottomleft: Home,
            bottomright: Home,
        },
        tabidx: 1,
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: () =>
        //         import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/apps',
        name: 'Apps',
        components: {
            default: Home,
            main: MyApps,
            header: Header,
            left: Home,
            right: Home,
            topleft: Home,
            topright: Home,
            bottomleft: Home,
            bottomright: Home,
        },
        tabidx: 2,
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: () =>
        //         import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/about',
        name: 'About',
        components: {
            default: Home,
            main: About,
            header: Header,
            left: Home,
            right: Home,
            topleft: Home,
            topright: Home,
            bottomleft: Home,
            bottomright: Home,
        },
        tabidx: 3,
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: () =>
        //         import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
