<template>
    <div class="home">
        <div class="contentpanel">
            <div class="teasers">
                <div class="row teaserrow">
                    <div class="col teasercol">
                        <div class="title">
                            <h1>Fortune Tiles</h1>
                            <a href="https://www.floodofcontent.com">
                                <img src="../assets/img/fortune-tiles.gif" />
                            </a>
                        </div>
                        <div class="about">
                            While experimenting with the WebGL library, I wrote
                            a Vue.Js recursion component that displays as a grid
                            of tiles which may be replaced with another grid of
                            tiles the size of that single tile. This would allow
                            for an infinite recursion, however I found percision
                            issues when zooming in. I left my demo at 3 levels
                            of recursion before the tile simply clears. Feel
                            free to check out the live demo, click the tile
                            image.
                            <a href="https://github.com/pcrats33/fortune-tiles"
                                >The code repo is also in github.</a
                            >
                        </div>
                    </div>
                </div>
                <div class="row teaserrow">
                    <div class="col teasercol">
                        <div class="title">
                            <h1>Raspberry Pi Security System</h1>
                            <a
                                href="https://github.com/pcrats33/spyer/blob/master/spyer.py">
                                <img src="../assets/img/security-cam.jpg" />
                            </a>
                        </div>
                        <div class="about">
                            I've taken an interest to Raspberry Pi and wanted to
                            see if I could make a security system with one. I
                            wanted to start simple, one Pi with a video camera
                            and motion sensor. Starting out, I flashed the 32gb
                            sd card with a copy of the Raspbian operating
                            system, which is a Linux flavor of Debian. I was
                            pleased to see that there are many libraries for
                            Raspberry Pi written for Python, one of my favorite
                            programming languages. Getting it set up was easy,
                            it was pretty much plug and play for the camera
                            libraries and motion sensor. Then I just had to
                            devise a way for it to record when someone walks by,
                            and store/send images. I will probably change the
                            code completely with further experience coding for
                            Raspberry Pi, but for now I have an end product that
                            does what I want on a basic level. The code can be
                            viewed on my github repository, click the image to
                            visit. Certain configuration values, such as e-mail
                            smtp server login credentials and where to send
                            pictures to are stored in a separate file not
                            followed by Git. Also I was able to use a hashing
                            encryption to store the password in another file. If
                            you wanted to use the code, just create the files
                            where it reads the information, or hardcode those
                            values. Videos are created in 20 second chunks
                            within a temporary directory and then moved to a
                            captures directory. This allows for another program
                            to sync these videos to the cloud. Whenever motion
                            is first detected an e-mail is sent with a still
                            picture capture. The essence of the code is an
                            infinite loop with delays that checks for motion and
                            turns the camera on or off accordingly. For future
                            versions I plan to incorporate a phone interface so
                            that I can turn it on/off remotely rather than with
                            the power cord. The script is ran in the
                            /etc/rc.local script, so all that is needed is for
                            the Pi to be plugged in and it will start the spy
                            cam script. I found working with the Raspberry Pi
                            enjoyable and easy, and it inspires imagination to a
                            myriad of projects well beyond a spy camera.
                        </div>
                    </div>
                </div>
                <div class="row teaserrow">
                    <div class="col teasercol">
                        <div class="title">
                            <h1>Daily Optimizations</h1>
                            <a href="https://www.dailyoptimizations.com">
                                <img
                                    src="../assets/img/daily-optimizations.gif" />
                            </a>
                        </div>
                        <div class="about">
                            I created this site in 2020 to improve my Vue.Js
                            skills and focus on the topic of time management.
                            Essentially this site allows me to write blog
                            articles that get stored in a database. It also gave
                            me a chance to appreciate the great front end
                            effects that can be done using Vue.js along with
                            flexbox and grid. And I added a bit of CSS
                            animations in there for transitions.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyApps',
}
</script>

<style scoped>
.title {
    text-align: center;
    padding: 0.25rem 0 0.25rem 0.5rem;
    font-weight: bold;
    font-size: 21px;
    color: #260000;
}
.about {
    cursor: pointer;
    text-decoration: none;
    color: #0b1445;
}
</style>
