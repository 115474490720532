<template>
    <div class="home">
        <div class="contentpanel">
            <div class="teasers">
                <div class="row teaserrow">
                    <div class="col teasercol">
                        <div class="title">
                            <h1>Some old bookmarks</h1>
                        </div>
                        <div class="links">
                            <p class="link">
                                <b><span>Learning</span></b>
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.udemy.com/the-complete-aspnet-mvc-5-course/?start=0"
                                        >The Complete ASP.NET MVC 5 Course |
                                        Udemy</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.reddit.com/r/webdev/comments/7h4hlw/what_are_the_best_up_to_date_ways_to_learn_aspnet/"
                                        >What are the best up to date ways to
                                        learn ASP.NET in C#? : webdev</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://gizmodo.com/mining-bitcoin-with-pencil-and-paper-1640353309"
                                        >Mining Bitcoin With Pencil and Paper</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.righto.com/2014/02/bitcoin-mining-hard-way-algorithms.html"
                                        >Bitcoin mining the hard way: the
                                        algorithms, protocols, and bytes</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <b><span>Front End</span></b>
                            </p>
                            <p class="link">
                                <span
                                    ><a href="https://bootswatch.com/"
                                        >Bootswatch: Free themes for
                                        Bootstrap</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://medium.com/@gogl.alex/how-to-properly-set-up-eslint-with-prettier-for-vue-or-nuxt-in-vscode-e42532099a9c"
                                        >How to properly set up Nuxt with ESLint
                                        and Prettier in VSCode</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://vuejs.org/v2/guide/events.html"
                                        >Event Handling — Vue.js</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://doc.babylonjs.com/how_to/how_to_use_actions"
                                        >Use Actions - Babylon.js
                                        Documentation</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://github.com/Beg-in/vue-babylonjs/issues/10"
                                        >Calling functions inside scene element
                                        (template) · Issue #10 ·
                                        Beg-in/vue-babylonjs · GitHub</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://vue-loader.vuejs.org/guide/pre-processors.html#sass-vs-scss"
                                        >Using Pre-Processors | Vue Loader</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://flaviocopes.com/vue-using-scss/"
                                        >How to use SCSS with Vue.js Single File
                                        Components</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a href="https://sass-lang.com/guide"
                                        >Sass: Sass Basics</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://stackoverflow.com/questions/6084360/using-node-js-as-a-simple-web-server"
                                        >Using node.js as a simple web server -
                                        Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://stackoverflow.com/questions/4502633/how-to-affect-other-elements-when-one-element-is-hovered"
                                        >html - How to affect other elements
                                        when one element is hovered - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://blog.sqreen.com/authentication-best-practices-vue/"
                                        >Authentication best practices for Vue -
                                        Sqreen Blog</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <b><span>Back End</span></b>
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://stackoverflow.com/questions/4929251/can-you-step-through-python-code-to-help-debug-issues?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa"
                                        >debugging - Can you step through python
                                        code to help debug issues? - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.tracetidwell.com/blog/deploy_flask_app"
                                        >Deploying A Flask App Trace Tidwell</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://auth0.com/docs/flows/authorization-code-flow"
                                        >Authorization Code Flow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <b><span>Internet Related</span></b>
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.faqs.org/rfcs/rfc1597.html"
                                        >RFC 1597 - Address Allocation for
                                        Private Internets (RFC1597)</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <b><span>Raspberry Pi</span></b>
                            </p>
                            <p class="link">
                                <span
                                    ><a href="https://rclone.org/"
                                        >rclone - rsync for cloud storage</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="http://picamera.readthedocs.io/en/release-1.12/fov.html"
                                        >6. Camera Hardware — Picamera 1.12
                                        documentation</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://github.com/RaspiRepo/Raspberry-PI-OneDrive"
                                        >GitHub -
                                        RaspiRepo/Raspberry-PI-OneDrive:
                                        Microsoft OneDrive storage for Raspberry
                                        PI.</a
                                    ></span
                                >
                            </p>
                            <p class="link">
                                <b><span>Linux</span></b>
                            </p>
                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.linuxuprising.com/2018/07/how-to-mount-onedrive-in-linux-using.html?m=1"
                                        >Mount OneDrive in Linux using Rclone</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <i
                                    ><span
                                        ><a href="https://pcrats.com/node/14"
                                            >A good .vimrc for coding</a
                                        ></span
                                    ></i
                                >
                            </p>

                            <p class="link">
                                <b
                                    ><span
                                        ><br />
                                        Raspberry Pi</span
                                    ></b
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.raspberrypi.org/documentation/remote-access/ssh/scp.md"
                                        >SCP Secure Copy</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://stackoverflow.com/questions/1346509/automate-scp-file-transfer-using-a-shell-script"
                                        >Stack Overflow: Automate SCP File
                                        Transfer with a Script</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><br />
                                    .Net</span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://www.pcrats.com/mvcnotes"
                                        >C# MVC .Net coding notes</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://github.com/mosh-hamedani/vidly-mvc-5"
                                        >Mosh's GitHub Vidly .net example</a
                                    ><br />
                                    <br />
                                    Automation</span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://pcrats.com/ahk"
                                        >Auto Hot Key examples</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <b
                                    ><span
                                        ><br />
                                        SQL</span
                                    ></b
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://dev.mysql.com/doc/refman/5.0/en/pattern-matching.html"
                                        >MySQL :: MySQL 5.0 Reference Manual ::
                                        3.3.4.7 Pattern Matching</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/310085"
                                        >Merge queries using db_merge |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.petefreitag.com/item/466.cfm"
                                        >SQL to Select a random row from a
                                        database table</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/17541312/alter-table-add-multiple-columns-after-column1"
                                        >mysql - alter table add MULTIPLE
                                        columns AFTER column1 - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>HTML5CSS3</span></b>
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.ibm.com/developerworks/library/x-html5/"
                                        >New elements in HTML 5</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://css-tricks.com/snippets/css/css-triangle/"
                                        >CSS Triangle | CSS-Tricks</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.echoecho.com/csstext.htm"
                                        >CSS Text - CSS tutorial</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs"
                                        >internet explorer 10 - Remove IE10's
                                        &quot;clear field&quot; X button on
                                        certain inputs? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://css-tricks.com/snippets/css/browser-specific-hacks/"
                                        >Browser Specific Hacks | CSS-Tricks</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://css-tricks.com/snippets/css/style-placeholder-text/"
                                        >Style Placeholder Text | CSS-Tricks</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/5306640/how-to-set-the-color-of-bullets-in-ul-li-lists-via-css-without-using-images-or"
                                        >css3 - How to set the Color of Bullets
                                        in UL/LI Lists via CSS, WITHOUT using
                                        images or span tags? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://blog.templatemonster.com/2011/04/14/jquery-css3-photo-gallery-tutorials-html5-website/"
                                        >Photo Gallery for Your HTML5 Website –
                                        jQuery and CSS3 Tutorials</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://bavotasan.com/2011/style-select-box-using-only-css/"
                                        >Style a Select Box Using Only CSS |
                                        bavotasan.com</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.yellowpipe.com/yis/tools/hex-to-rgb/color-converter.php"
                                        >Color converter - HEX to RGB and rgb to
                                        hex color converter</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://demosthenes.info/blog/586/CSS-Fluid-Image-Techniques-for-Responsive-Site-Design"
                                        >demosthenes.info – CSS Fluid Image
                                        Techniques for Responsive Site Design</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://css-tricks.com/override-inline-styles-with-css/"
                                        >Override Inline Styles with CSS |
                                        CSS-Tricks</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/8447553/css-non-wrapping-floating-divs"
                                        >html - CSS non-wrapping floating divs -
                                        Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/3380252/how-to-create-proportional-image-height-width"
                                        >html - how to create proportional image
                                        height/width - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://callmenick.com/2014/05/13/css-toggle-switch-examples/"
                                        >CSS Toggle Switch Examples - Call Me
                                        Nick</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://robertnyman.com/2010/02/24/css-display-inline-block-why-it-rocks-and-why-it-sucks/"
                                        >CSS display: inline-block: why it
                                        rocks, and why it sucks - Robert's
                                        talk</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/16594891/make-children-divs-the-height-of-tallest-child"
                                        >css - Make children divs the height of
                                        tallest child - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/16398823/why-does-a-div-with-display-table-cell-not-affected-by-margin"
                                        >html - Why does a div with
                                        &quot;display: table-cell;&quot; not
                                        affected by margin? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://css-tricks.com/fighting-the-space-between-inline-block-elements/"
                                        >Fighting the Space Between Inline Block
                                        Elements | CSS-Tricks</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.bennadel.com/blog/1734-using-css-fixed-position-elements-across-browsers.htm"
                                        >Using CSS Fixed Position Elements
                                        Across Browsers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/450903/make-div-width-equal-to-child-contents"
                                        >css - Make Div Width Equal To Child
                                        Contents - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://foundation.zurb.com/docs/components/range_slider.html"
                                        >Range Sliders | Foundation Docs</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://caniuse.com/#feat=css-sel3"
                                        >Can I use... Support tables for HTML5,
                                        CSS3, etc</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.smashingmagazine.com/2007/05/01/css-float-theory-things-you-should-know/"
                                        >CSS Float Theory: Things You Should
                                        Know | Smashing Magazine</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://colllor.com/"
                                        >Color Palette Generator - Colllor</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/10019797/pure-css-close-button"
                                        >javascript - Pure css close button -
                                        Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://css-tricks.com/viewport-sized-typography/"
                                        >Viewport Sized Typography |
                                        CSS-Tricks</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://codeitdown.com/responsive-font-size-css/"
                                        >Responsive Font Size with CSS |
                                        codeitdown.com</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.edlazorvfx.com/ysu/html/ascii.html"
                                        >ascii code</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://codepen.io/surjithctly/pen/pLDwe"
                                        >Pure CSS Slide Down Toggle</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/10097083/jquery-close-button-effect-style"
                                        >css - jQuery close button effect/style
                                        - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://the-echoplex.net/flexyboxes/"
                                        >Flexy Boxes — CSS flexbox playground
                                        and code generation tool</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>Drupal Modules</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/206761"
                                        >Creating a module configuration
                                        (settings) page Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/1074360"
                                        >Creating modules - a tutorial Drupal
                                        7.x Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.youtube.com/watch?v=53Kq_JLxjds"
                                        >? Drupal Quickstart 0.9 Step Debugging
                                        - YouTube</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://drupal.org/project/custom_menu_perms"
                                        >Custom Menu Permissions | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/270000"
                                        >Controlling Access to Content Overview
                                        | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://drupal.org/project/field_collection"
                                        >Field collection | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://drupal.org/project/views_fieldsets"
                                        >Views fieldsets | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://drupal.org/project/shorten"
                                        >Shorten URLs | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/2274953"
                                        >Default revision is incorrectly set
                                        when host entity is NOT default
                                        [#2274953] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/project/eva"
                                        >EVA: Entity Views Attachment |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://jeromejaglale.com/doc/drupal7/views_slideshow_tutorial"
                                        >Drupal 7: Views Slideshow Tutorial</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.webomelette.com/10-drupal-views-modules"
                                        >10 cool modules that integrate with
                                        Drupal Views | Web Omelette</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://www.webomelette.com/"
                                        >Web Omelette</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/live_css"
                                        >Live CSS | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/multiform"
                                        >Multiple forms | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://groups.drupal.org/node/168009"
                                        >Modules that Extend the Media Module |
                                        Drupal Groups</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://groups.drupal.org/media"
                                        >Media | Drupal Groups</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/cdn"
                                        >CDN | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://groups.drupal.org/node/19746"
                                        >Media Module F.A.Q. | Drupal Groups</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/draggableviews"
                                        >DraggableViews | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/329511"
                                        >Views Embed Form: Embedding a form in a
                                        Views display | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/inline_entity_form"
                                        >Inline Entity Form | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/eva"
                                        >EVA: Entity Views Attachment |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/editableviews"
                                        >Editable Views | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/documentation/modules/media"
                                        >Media | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/jquery_update"
                                        >jQuery Update | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/insert"
                                        >Insert | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://groups.drupal.org/node/20291"
                                        >File Uploading and Managing | Drupal
                                        Groups</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/field_permissions"
                                        >Field Permissions | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/field_extrawidgets"
                                        >Field extra widgets | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/photos"
                                        >Album Photos | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/rate"
                                        >Rate | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/colorbox"
                                        >Colorbox | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/fivestar"
                                        >Fivestar | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/media_gallery"
                                        >Media Gallery | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1361210"
                                        >'Workbench moderation: current' views
                                        filter does not list all content
                                        [#1361210] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/filedepot"
                                        >filedepot | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/addressfield"
                                        >Address Field | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1181966"
                                        >Creating a numeric pager |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/44662/make-views-slideshow-responsive-remove-inline-styles"
                                        >theming - Make views slideshow
                                        responsive - remove inline styles -
                                        Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/flexslider"
                                        >Flex Slider | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/filter_harmonizer"
                                        >Views Filter Harmonizer | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/field_group"
                                        >Field Group | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/file_lock"
                                        >File Lock | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/references"
                                        >References | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/filefield_sources"
                                        >FileField Sources | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/special_menu_items"
                                        >Special menu items | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/views_cache_bully"
                                        >Views cache bully | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/label_help"
                                        >Label Help | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/pathauto_persist"
                                        >Pathauto persistent state |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/views_accelerator"
                                        >Views Accelerator | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/imagecache_actions"
                                        >ImageCache Actions | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/rename_admin_paths"
                                        >Rename Admin Paths | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/recaptcha"
                                        >reCAPTCHA | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/coder"
                                        >Coder | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/reference_option_limit"
                                        >Reference field option limit |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/shs"
                                        >Simple hierarchical select |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/chosen"
                                        >Chosen | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/2035157"
                                        >Limiting options in views exposed
                                        filters with ajax [#2035157] |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/override_node_options"
                                        >Override Node Options | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/expire"
                                        >Cache Expiration | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/ddblock"
                                        >Dynamic display block | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/sandbox/issahh/2432645"
                                        >CKEditor AutoEmbed | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/conditional_fields"
                                        >Conditional Fields | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/field_conditional_state"
                                        >Field Conditional States |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/plug"
                                        >Plug | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/plug_field"
                                        >Plug Field | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/attachment_links"
                                        >Attachment Links | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/file_entity_revisions"
                                        >File Entity Revisions | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/project/oci8"
                                        >Oracle integration | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>PHP IDE</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>setup XDebug</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://deeson-online.co.uk/labs/debugging-drupal-xdebug-acquia-dev-desktop-and-php-storm"
                                        >Debugging Drupal with XDebug, Acquia
                                        Dev Desktop and PHP Storm Deeson
                                        Online</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.phpeclipse.com/wiki/Howto/XDebugAndPHPEclipse"
                                        >Howto-XDebugAndPHPEclipse –
                                        PHPEclipse</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://xdebug.org/docs/remote"
                                        >xdebug Documentation</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.jetbrains.com/phpstorm/marklets/"
                                        >Xdebug &amp; Zend Debugger bookmarklets
                                        generator for PhpStorm</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://wiki.eclipse.org/Debugging_using_XDebug"
                                        >Debugging using XDebug -
                                        Eclipsepedia</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://programmingbulls.com/debugging-drupal-php--xdebug-eclipse"
                                        >Debugging Drupal or any PHP Application
                                        with XDebug and Eclipse PDT</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>Drupal Arrays</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>nodes</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/6127/how-can-i-detect-when-a-node-is-changed-from-published-to-unpublished"
                                        >hooks - How can I detect when a node is
                                        changed from 'Published' to
                                        'Unpublished'? - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://pingv.com/blog/custom-drupal-7-view-modes-different-ways-see-your-content"
                                        >Custom Drupal 7 view modes: Different
                                        ways to see your content | Pingv ::
                                        Creative Engineering</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.phase2technology.com/blog/drupal-7-node-access-grants-locks-and-keys/"
                                        >Drupal 7 Node Access: Grants, Locks,
                                        and Keys | Phase2</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21node%21node.module/group/node_access/7"
                                        >Node access rights | node.module |
                                        Drupal 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/examples/node_access_example!node_access_example.module/7"
                                        >node_access_example.module | Examples 7
                                        | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21field%21field.module/function/field_get_items/7"
                                        >field_get_items | field.module | Drupal
                                        7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://fooninja.net/2011/06/06/updating-nodes-programmatically-in-drupal-7/"
                                        >Updating nodes programmatically in
                                        Drupal 7 - fooninja.net</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21node%21node.api.php/group/node_api_hooks/7"
                                        >Node API Hooks | node.api.php | Drupal
                                        7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/6831024/how-to-hide-a-field-on-node-data-entry-form-in-drupal"
                                        >How to hide a field on node data entry
                                        form in drupal? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://www.drupal.org/node/49768"
                                        >Node object reference | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/94498/how-to-use-hook-node-presave-to-change-fields-vluae"
                                        >hooks - How to use hook_node_presave to
                                        change field's vluae - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/13529636/drupal-alter-node-edit-form"
                                        >drupal alter node edit form - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1388922"
                                        >Drupal 7 - Create a Node in Code |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/324004"
                                        >ARCHIVE: How to force a different theme
                                        for specific Admin pages when the
                                        Administration theme is set (eg.
                                        node/add or node/edit) | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1436260"
                                        >Saving nodes outside Workbench
                                        Moderation leads to incorrect state
                                        transitions (e.g., &quot;needs
                                        review&quot; appearing as published)
                                        [#1436260] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>views</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.brenthartmann.com/blog/how-use-hookqueryalter-drupal-7"
                                        >How to Use hook_query_alter in Drupal 7
                                        | Brent Hartmann dot Com</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/68124/make-custom-form-submit-data-to-view-filters"
                                        >7 - Make custom form submit data to
                                        view filters - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.yellowpencil.com/blog/last-ditch-fix-programmatically-changing-drupal-7-view"
                                        >Yellow Pencil | The Last-Ditch Fix -
                                        Programmatically changing a Drupal 7
                                        view</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://isovera.com/blog/programmatically-creating-forms-views-drupal-7"
                                        >Programmatically Creating Forms with
                                        Views in Drupal 7 | Isovera Ideas and
                                        Insights Blog</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/views/views.api.php/function/hook_views_data_alter/7"
                                        >hook_views_data_alter | views.api.php |
                                        Views 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.zyxware.com/articles/3280/drupal-how-to-add-view-relationships-programmatically-with-custom-database-tables-in-drupal-7"
                                        >[Drupal] How to add View relationships
                                        programmatically with custom database
                                        tables in Drupal 7 | Zyxware
                                        Technologies</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.drupalcode.com/api/function/views_invalidate_cache/contrib-5.x-1.x"
                                        >views_invalidate_cache |
                                        drupalcode.com</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1847086"
                                        >add/remove fields from view depending
                                        on condition with hook | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/749452"
                                        >$view-&gt;display_handler-&gt;set_option('footer',$options)
                                        does not add footer in pre-render hook
                                        [#749452] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://scito.ch/content/show-related-nodes-block-based-taxonomy-terms-views-3-and-drupal-7"
                                        >Show related nodes in a block based on
                                        taxonomy terms with Views 3 and Drupal 7
                                        | Scito</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/2063193"
                                        >Inject html code into views field using
                                        hooks | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://groups.drupal.org/node/82219"
                                        >Modify views filters programatically |
                                        Drupal Groups</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://yellowpencil.com/blog/last-ditch-fix-programmatically-changing-drupal-7-view"
                                        >Yellow Pencil | The Last-Ditch Fix -
                                        Programmatically changing a Drupal 7
                                        view</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/310077"
                                        >Query alteration | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.silviogutierrez.com/blog/optimizing-drupal-views-right-way/"
                                        >Optimizing Drupal Views the Right Way |
                                        Silvio's Soapbox</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.appnovation.com/blog/using-hook-views-query-alter"
                                        >Using Hook Views Query Alter |
                                        Appnovation Technologies Inc.</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.packtpub.com/books/content/creating-views-3-programmatically"
                                        >Creating Views 3 Programmatically |
                                        Packt</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/64106/extract-views-row-values"
                                        >7 - extract views $row values - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/358546#comment-7720211"
                                        >[Solved] set views exposed filters
                                        default to empty result set |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>fields</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://clikfocus.com/blog/how-set-custom-field-type-using-drupal-7-fields-api"
                                        >clikfocus.com/blog/how-set-custom-field-type-using-drupal-7-fields-api</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21field%21field.info.inc/function/field_info_field/7"
                                        >field_info_field | field.info.inc |
                                        Drupal 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules!field!field.module/function/field_get_items/7"
                                        >field_get_items | field.module | Drupal
                                        7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.digett.com/blog/01/18/2012/change-output-single-field-drupal-7-node"
                                        >Override Drupal 7 Field Markup with the
                                        template_preprocess_field Function |
                                        Digett</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.phase2technology.com/category/drupal/page/36/"
                                        >Drupal | Phase2 | Page 36</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21field%21field.crud.inc/function/field_purge_batch/7"
                                        >field_purge_batch | field.crud.inc |
                                        Drupal 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/46085/programatically-remove-a-field-from-a-node"
                                        >7 - Programatically remove a field from
                                        a node - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.computerminds.co.uk/articles/rendering-drupal-7-fields-right-way"
                                        >Rendering Drupal 7 fields (the right
                                        way) | ComputerMinds</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.phase2technology.com/blog/compound-fields-in-drupal-7/"
                                        >Compound fields in Drupal 7 | Phase2</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.makina-corpus.org/blog/code-snippet-human-readable-machine-name"
                                        >Code snippet: human readable to machine
                                        name | Le blog de Makina Corpus</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>media</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/11742763/drupal-7-use-media-selector-from-the-media-module-in-custom-module"
                                        >Drupal 7 Use media selector from the
                                        media module in custom module - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://cheppers.com/blog/create-custom-browser-widget-media-module"
                                        >Create custom browser widget to the
                                        media module | Cheppers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.slideshare.net/G_N/drupal-7-media-modules"
                                        >Drupal 7 Media modules</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://shibashake.com/wordpress-theme/how-to-hook-into-the-media-upload-popup-interface"
                                        >How to Hook into the Media Upload Popup
                                        Interface</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules!image!image.module/function/image_style_url/7"
                                        >image_style_url | image.module | Drupal
                                        7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>patches</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1708438"
                                        >Incompatibility Between Chaos Tools
                                        Auto Submit and Autocomplete Fields!
                                        [#1708438] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/2267943"
                                        >Workbench moderation integration
                                        [#2267943] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/2294973#comment-9016311"
                                        >Date format in calendar title remains
                                        default (e.g., month view date shows as
                                        July 1, 2014) [#2294973] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>core</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a href="https://api.drupal.org/api/drupal"
                                        >Drupal 7 | API reference | Drupal
                                        API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21module.inc/group/hooks/7"
                                        >Hooks | module.inc | Drupal 7 | Drupal
                                        API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21user%21user.module/function/user_load/7"
                                        >user_load | user.module | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/5344754/drupal-7-how-to-display-node-add-sometype-form-on-another-page"
                                        >Drupal 7 - How to display
                                        node/add/sometype form on another page?
                                        - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/37674/how-drupal-decide-execution-order-of-same-hook-in-different-modules"
                                        >how drupal decide execution order of
                                        same hook in different modules? - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21cache.inc/function/cache_clear_all/7"
                                        >cache_clear_all | cache.inc | Drupal 7
                                        | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21bootstrap.inc/function/drupal_set_message/7"
                                        >drupal_set_message | bootstrap.inc |
                                        Drupal 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://tlhotisfs1/otis/Projects/Current%20Projects/Web%20Content%20Management/Deliverables/Eclipse%20Documentation/drupal-hook_templates.xml"
                                        >drupal-hook_templates.xml</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/69694/programatically-create-new-user-with-custom-fields-in-drupal-7"
                                        >programatically create new user with
                                        custom fields in drupal 7 - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21user%21user.module/function/user_save/7"
                                        >user_save | user.module | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://codekarate.com/blog/create-user-account-drupal-7-programmatically"
                                        >Create a user account in Drupal 7
                                        programmatically | Code Karate</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://john.albin.net/drupal/arrays-of-doom"
                                        >Rethinking Drupal’s Theme/Render Layer,
                                        Drupal 7 and the Arrays of Doom |
                                        JohnAlbin</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1850384"
                                        >Any good examples of sending email from
                                        a Drupal 7 module? | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/89949/how-do-i-get-the-timezone-form-reference-on-a-date-field"
                                        >7 - How do I get the timezone form
                                        reference on a date field? - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://pixeljets.com/blog/writing-robust-code-uses-fields-drupal-7"
                                        >Writing robust code that uses fields,
                                        in Drupal 7 | Drupal and Symfony2
                                        developers and designers you were trying
                                        to find: Pixeljets</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://ayesh.me/drupal-bad-practices"
                                        >Drupal bad practices | Ayesh
                                        Karunaratne</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.drupalcoder.com/blog/disable-drupals-page-cache-for-some-pages.html"
                                        >Disable Drupal's page cache for some
                                        pages | Drupal coder</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21user%21user.module/function/_user_mail_notify/7"
                                        >_user_mail_notify | user.module |
                                        Drupal 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1891228"
                                        >image_style_deliver can create invalid
                                        headers [#1891228] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21bootstrap.inc/function/drupal_static/7"
                                        >drupal_static | bootstrap.inc | Drupal
                                        7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>menus</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21menu.inc/group/menu/7"
                                        >Menu system | menu.inc | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>forms</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://zufelt.ca/blog/drupal-7-form-api-accessibility-titledisplay"
                                        >Drupal 7 Form API accessibility:
                                        #title_display | Everett Zufelt</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/5014469/add-css-property-for-individual-components-in-form-elements-drupal"
                                        >Add css property for individual
                                        components in form elements - Drupal -
                                        Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/44323/adding-form-placeholders-to-text-input-fields"
                                        >theming - Adding Form Placeholders to
                                        text input fields - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1850410"
                                        >$form_state keys | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://randyfay.com/content/form-api-changes-drupal-7-part-1-formstate-changes"
                                        >Form API Changes for Drupal 7, Part 1:
                                        $form_state changes | RandyFay.com</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.codeenigma.com/en/blog/validating-submitting-forms-other-websites-drupal"
                                        >Validating &amp; Submitting Forms To
                                        Other Websites With Drupal | Code
                                        Enigma</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/48564/how-to-post-to-remote-url-on-form-submit"
                                        >How to post to remote url on form
                                        submit - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/10490253/how-to-save-a-session-variable-in-drupal-7"
                                        >php - How to save a session variable in
                                        drupal 7? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/developer!topics!forms_api_reference.html/7#attributes"
                                        >Form API Reference | Drupal 7 | Drupal
                                        API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.inclind.com/journal/development/adding-custom-form-api-elements-to-drupal/detail.htm"
                                        >Adding Custom Form API Elements to
                                        Drupal | Inclind - Delaware web design
                                        company specializing in web development,
                                        custom Drupal development, and search
                                        engine optimization.</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/283065"
                                        >HowTo: Taming the form buttons |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/781556"
                                        >Two Form Submit Buttons with Same Value
                                        but Different Parents | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21system%21system.api.php/function/hook_form_FORM_ID_alter/7"
                                        >hook_form_FORM_ID_alter |
                                        system.api.php | Drupal 7 | Drupal
                                        API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/4289/how-to-override-some-admin-theme-css-rules"
                                        >7 - How to override some admin theme
                                        CSS rules? - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/comment/28464#comment-28464"
                                        >Form Error Validation</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/12626086/drupal-7-make-field-required-in-form-user-profile-form-alter"
                                        >drupal 7 make field required in
                                        form_user_profile_form_alter - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.jaypan.com/tutorial/drupal-7-ajax-driven-multi-step-forms-non-javascript-fallback"
                                        >Tutorial: Drupal 7: Ajax driven
                                        multi-step forms with a non-javascript
                                        fallback | Jaypan | Japan Tokyo Yokohama
                                        Japan Drupal web design and
                                        development</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.aspcode.net/ppst/63547274810018958069242/how-to-change-error-message-displayed-failed-form-element-validation-to-be-different-than-the-elements-title-text"
                                        >How to change error-message displayed @
                                        failed form-element validation to be
                                        different than the element's
                                        '#title&quot; text?</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/291680"
                                        >Redirecting with query string |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/759222"
                                        >#tree does not default to TRUE, fails
                                        to meet developer expectations [#759222]
                                        | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21system%21system.module/function/confirm_form/7"
                                        >confirm_form | system.module | Drupal 7
                                        | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>files</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1227706"
                                        >Add a file entity access API [#1227706]
                                        | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupaldr.blogspot.com/2012/02/attaching-files-to-nodes.html"
                                        >Drupal Dr.: Attaching files to nodes
                                        programmatically in Drupal 7</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://rowlandsgroup.com/articles/2012/02/nick-schuch/programmatically-insert-local-files-managed-files"
                                        >Programmatically Insert Local Files to
                                        Managed Files | Drupal Articles by Nick
                                        Schuch | Rowlands Group</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/34244/creating-a-file-object-from-a-file-already-in-public-drupal-7"
                                        >Creating a file object from a file
                                        already in public:// (drupal 7) - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.wesjones.net/home/2011/03/drupal-6-how-to-change-filename-on-upload"
                                        >Drupal 6: How to Change Filename on
                                        Upload | WesJones.net</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/2163209"
                                        >Add alternate text to file icon
                                        [#2163209] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21file.inc/function/file_create_url/7"
                                        >file_create_url | file.inc | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/560424"
                                        >Writing stream wrappers | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>module help</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://alvinalexander.com/drupal-code-examples/drupal-7-votingapi-project/views/votingapi.views.inc.shtml"
                                        >Drupal example - votingapi.views.inc -
                                        appears, array, handler, help, on,
                                        the</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.wunderkraut.com/blog/get-wild-with-facets-extending-facet-api/2012-01-17"
                                        >Get Wild With Facets – Extending Facet
                                        API | Wunderkraut</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupalcontrib.org/api/drupal/contributions!ctools!ctools.api.php/7"
                                        >ctools.api.php | Drupal 7 |
                                        DrupalContrib</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>entity</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1842304"
                                        >Example: Programmatically create and
                                        update field collection with Entity API
                                        | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>templates</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/303586"
                                        >Preprocessors for each view [#303586] |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/2383865/how-do-i-use-theme-preprocessor-functions-for-my-own-templates/2387782#2387782"
                                        >drupal - How do I use theme
                                        preprocessor functions for my own
                                        templates? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1092122"
                                        >Theming Node Forms in Drupal 7 |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/812/how-do-i-change-a-theme-based-on-the-url"
                                        >7 - How do I change a theme based on
                                        the URL? - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/312/how-to-a-use-a-template-file-to-theme-a-form"
                                        >theming - How to a use a template file
                                        to theme a form? - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1200216"
                                        >Theming forms in your theme |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/892296"
                                        >Need logo to link to outside URL
                                        [#892296] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://cocoate.com/ddbook/scary-render-array"
                                        >The Scary Render Array | cocoate.com</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>DB</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://zgadzaj.com/how-to-change-the-machine-name-of-a-content-field-in-drupal-7"
                                        >How to change the machine name of a
                                        content field in Drupal 7 ::
                                        Change(b)log</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.webomelette.com/drupal-webform-submission-presave-hook"
                                        >How to alter the Webform submission
                                        information before being saved into the
                                        database | Web Omelette</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/33609/how-to-write-a-drupal-7-query-with-count-and-having-properties"
                                        >hooks - How to write a Drupal 7 query
                                        with &quot;count&quot; and
                                        &quot;having&quot; properties? - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>jQuery</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://zerotodrupal.com/content/better-way-add-javascript-and-css-hooklibrary"
                                        >A Better Way to Add Javascript and CSS
                                        with hook_library() | Zero To Drupal</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://maxbronsema.com/blog/2013/01/18/how-to-include-jquery-ui-effects-in-drupal-7/comment-page-1/"
                                        >How to include jQuery.ui effects in
                                        Drupal 7 | Max Bronsema</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/96178/how-to-load-third-party-php-library-in-drupal-7-module"
                                        >libraries - How to load third party php
                                        library in drupal 7 module? - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>permissions</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/13450/what-is-the-hook-to-alter-workflow-access-permissions"
                                        >6 - What is the hook to alter workflow
                                        access permissions? - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>workflow</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.ionsden.com/article/problems-workflow-and-revisioning-drupal"
                                        >Problems with Workflow and Revisioning
                                        in Drupal | IonsDen</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>HTML</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/5382730/link-inside-select-box"
                                        >html - Link Inside Select Box - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/503093/how-can-i-make-a-redirect-page-in-jquery-javascript"
                                        >How can I make a redirect page in
                                        jQuery/JavaScript? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/406192/how-to-get-the-current-url-in-javascript"
                                        >jquery - How to get the current URL in
                                        JavaScript? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>jQuery</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://jqueryui.com/autocomplete/#combobox"
                                        >Autocomplete | jQuery UI</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.benknowscode.com/2013/03/finishing-jquery-ui-autocomplete_4743.html"
                                        >Ben.Knows.Code(): Finishing the jQuery
                                        UI AutoComplete ComboBox Demo</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/11036724/display-placeholder-text-in-empty-sortable-with-jquery-ui"
                                        >Display placeholder text in empty
                                        sortable with jQuery UI - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.zyxware.com/articles/3295/drupal-7-how-to-create-a-simple-javscript-popup-without-using-third-party-javascript-libraries"
                                        >[Drupal 7] How to create a simple
                                        Javscript popup without using third
                                        party Javascript libraries? | Zyxware
                                        Technologies</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/756722"
                                        >Managing JavaScript in Drupal 7 |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/1133770/how-do-i-convert-a-string-into-an-integer-in-javascript"
                                        >How do I Convert a String into an
                                        Integer in JavaScript? - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.bennadel.com/blog/1805-jquery-events-mouseover-mouseout-vs-mouseenter-mouseleave.htm"
                                        >jQuery Events: MouseOver / MouseOut vs.
                                        MouseEnter / MouseLeave</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.bennadel.com/blog/1856-using-jquery-s-animate-step-callback-function-to-create-custom-animations.htm"
                                        >Using jQuery's Animate() Step Callback
                                        Function To Create Custom Animations</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/8042174/jquery-click-on-a-child-ignore-parent-click"
                                        >jquery click on a child ignore parent
                                        click - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://coderwall.com/p/ahazha"
                                        >Jack Hsu : Force redraw on an element
                                        (jQuery)</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.eccesignum.org/blog/solving-display-refreshredrawrepaint-issues-in-webkit-browsers"
                                        >Solving Display Refresh/Redraw/Repaint
                                        Issues in Webkit Browsers |
                                        eccesignum</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/20527765/executing-window-load-and-window-resize-in-drupal-7"
                                        >javascript - Executing $(window).load()
                                        and $(window).resize() in Drupal 7 -
                                        Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/15100576/how-to-remove-an-event-listener-in-javascript"
                                        >jquery - How to remove an event
                                        listener in javascript? - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/203198/event-binding-on-dynamically-created-elements"
                                        >jquery - Event binding on dynamically
                                        created elements? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/3714628/jquery-get-the-location-of-an-element-relative-to-window"
                                        >javascript - jQuery get the location of
                                        an element relative to window - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/4655273/jquery-window-scrolltop-but-no-window-scrollbottom"
                                        >javascript - jquery:
                                        $(window).scrollTop() but no
                                        $(window).scrollBottom() - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/3724749/html-input-fields-does-not-get-focus-when-clicked"
                                        >javascript - HTML input fields does not
                                        get focus when clicked - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1936178"
                                        >Run jquery after AJAX view update
                                        [#1936178] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/3299/redirect-user-after-node-add-edit"
                                        >7 - Redirect user after node add/edit -
                                        Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/4871389/how-to-get-the-text-value-of-a-clicked-link"
                                        >jquery - How to get the text value of a
                                        clicked link? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/1181575/javascript-determine-whether-an-array-contains-a-value"
                                        >Javascript: Determine whether an array
                                        contains a value - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/15171266/settimeout-is-not-waiting"
                                        >javascript - setTimeout() is not
                                        waiting - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/210717/using-jquery-to-center-a-div-on-the-screen"
                                        >html - Using jQuery to center a DIV on
                                        the screen - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>SQL</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/16256250/create-view-in-a-trigger"
                                        >mysql - Create View in a Trigger -
                                        Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/310077"
                                        >Query alteration | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="https://drupal.org/node/310086"
                                        >Conditional clauses | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/21909/increment-field-value-in-update-query"
                                        >7 - Increment field value in update
                                        query - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21database%21select.inc/class/SelectQuery/7"
                                        >SelectQuery | select.inc | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://docs.oracle.com/cd/E13085_01/doc/timesten.1121/e13076/sqlexamples.htm"
                                        >Examples Using TimesTen SQL in
                                        PL/SQL</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/85643/using-db-select-how-can-i-select-all-nodes-with-any-of-an-array-of-taxonomy-ter"
                                        >mysql - Using db_select, how can I
                                        select all nodes with any of an array of
                                        taxonomy terms and order by the number
                                        of terms it is associated with? - Drupal
                                        Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://dev.mysql.com/doc/refman/5.0/en/blob.html"
                                        >MySQL :: MySQL 5.0 Reference Manual ::
                                        11.4.3 The BLOB and TEXT Types</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/6711393/how-do-i-minus-2-hours-from-a-date-time-field-in-every-record-in-a-table"
                                        >mysql - How do I minus 2 hours from a
                                        date/time field in EVERY record in a
                                        table? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://dba.fyicenter.com/faq/mysql/Count-Groups-Returned-from-GROUP-BY.html"
                                        >MySQL Tutorial - Counting Groups
                                        Returned from GROUP BY</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>Drupal Entities</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://growingventuresolutions.com/blog/drupal-7-multistep-forms-using-variable-functions.html"
                                        >Drupal 7 multistep forms using variable
                                        functions | Growing Venture Solutions -
                                        GVS</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a href="http://drupal.stackexchange.com/"
                                        >Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>drupal system</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://ygerasimov.com/drupal7-upload-private-file-easy"
                                        >Drupal 7 upload private files easy |
                                        ygerasimov</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/18808779/drupal-7-fill-custom-form-image-field-with-image-from-database"
                                        >Drupal 7 - Fill custom form image field
                                        with image from database - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/18997423/display-uploaded-image-drupal-7-form-api"
                                        >php - Display uploaded image - Drupal 7
                                        Form API - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/25516/image-upload-in-a-custom-module"
                                        >form api - Image Upload in a Custom
                                        Module - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/250790"
                                        >Updating modules | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.metaltoad.com/blog/fixing-caching-views-exposed-filters-drupal-7"
                                        >How to Fix Caching for Views With
                                        Exposed Filters in Drupal 7 | Metal
                                        Toad</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/31109/changing-the-file-of-a-file-field-drupal-7"
                                        >filefield - Changing the file of a file
                                        field (drupal 7) - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21file.inc/function/file_move/7"
                                        >file_move | file.inc | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/includes%21file.inc/group/file/7"
                                        >File interface | file.inc | Drupal 7 |
                                        Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/763846"
                                        >Get Full URL Including Query String
                                        with PHP | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://randyfay.com/content/drupal-7-file-api-changes"
                                        >Drupal 7 File API Changes |
                                        RandyFay.com</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/877212"
                                        >Important Drupal 7 File API Functions |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://drupal.stackexchange.com/questions/28735/permanently-disable-auto-preview-in-views"
                                        >7 - Permanently disable Auto Preview in
                                        views? - Drupal Answers</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1782680"
                                        >Can't get fonts or line breaks!
                                        [#1782680] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.millwoodonline.co.uk/blog/drupal-7-performance-gains-with-views-cache"
                                        >Drupal 7 performance gains with Views
                                        cache | Millwood Online Blog</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1036962#comment-6665274"
                                        >Edit link destination incorrect when
                                        using AJAX-enabled views [#1036962] |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1650420"
                                        >Ajax error after editing content
                                        navigated to after an ajax content
                                        search search. [#1650420] |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://adellefrank.com/blog/drupal-database-tables-files-backup-migrate"
                                        >Backing up and migrating: where content
                                        is stored in Drupal database tables and
                                        files | Adelle Frank</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/2360249"
                                        >Correctly display tertiary menu list
                                        items with opacity in Firefox 34
                                        [#2360249] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1191484"
                                        >Workbench Access Views Contextual
                                        Filters [#1191484] | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.cameronandwilding.com/blog/pablo/10-most-critical-drupal-security-risks"
                                        >The 10 most critical Drupal security
                                        risks | Cameron &amp; Wilding</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://blog.jambura.com/2013/09/04/disable-varnish-cache-using-http-headers/"
                                        >Disable Varnish Cache Using HTTP
                                        Headers | The NewComer</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules!field!field.api.php/function/hook_field_storage_pre_update/7"
                                        >hook_field_storage_pre_update |
                                        field.api.php | Drupal 7 | Drupal API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://api.drupal.org/api/drupal/modules%21system%21system.api.php/function/hook_module_implements_alter/7"
                                        >hook_module_implements_alter |
                                        system.api.php | Drupal 7 | Drupal
                                        API</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.civicactions.com/blog/2015/jan/27/dockerizing-drupal-project-development-and-testing"
                                        >Dockerizing Drupal for Project
                                        Development and Testing |
                                        CivicActions</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>php</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/3861353/structs-data-type-in-php"
                                        >Structs data type in php? - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/2668854/sanitizing-strings-to-make-them-url-and-filename-safe"
                                        >php - Sanitizing strings to make them
                                        URL and filename safe? - Stack
                                        Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/8657541/getting-object-variable-using-string-variable"
                                        >php - Getting object variable using
                                        string + variable - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>gfx</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a href="http://www.1x1px.me/"
                                        >Ever needed a transparent 1x1 PNG
                                        pixel? Grab one here!</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://github.com/google/material-design-icons"
                                        >google/material-design-icons ·
                                        GitHub</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://google.github.io/material-design-icons/"
                                        >Material Icons Index</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>frameworks</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://developers.facebook.com/docs/web/tutorials/scrumptious/open-graph-object"
                                        >Define an Open Graph object</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>computer</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a href="http://ss64.com/nt/mstsc.html"
                                        >MSTSC - RDP / Terminal Server
                                        Connection | Windows CMD | SS64.com</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>networks and email</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://technet.microsoft.com/en-us/library/dn205071(v=exchg.150).aspx"
                                        >Anti-spam message headers: Exchange
                                        Online Help</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://tools.ietf.org/html/rfc3834"
                                        >RFC 3834 - Recommendations for
                                        Automatic Responses to Electronic
                                        Mail</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://azure.microsoft.com/en-us/documentation/articles/fundamentals-identity/"
                                        >Azure Identity</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://mikecrowley.wordpress.com/tag/dirsync/"
                                        >Dirsync | Mike Crowley's Whiteboard</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://mikecrowley.wordpress.com/2013/10/23/dirsync-and-disabled-users-the-blockcredential-attribute-part-two/"
                                        >DirSync and Disabled Users: The
                                        BlockCredential Attribute [Part 2] |
                                        Mike Crowley's Whiteboard</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>ldap</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://blogs.technet.com/b/heyscriptingguy/archive/2005/05/12/how-can-i-get-a-list-of-all-the-disabled-user-accounts-in-active-directory.aspx"
                                        >How Can I Get a List of All the
                                        Disabled User Accounts in Active
                                        Directory? - Hey, Scripting Guy! Blog -
                                        Site Home - TechNet Blogs</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://wemakeitsimple.blogspot.com/2013/03/integrating-drupal-with-active.html"
                                        >Integrating Drupal with Active
                                        Directory with LDAP Integration
                                        Module</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>ORACLE</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://asktom.oracle.com/pls/asktom/f?p=100:11:0%3A%3A%3A%3AP11_QUESTION_ID:3581757800346555562"
                                        >Ask Tom &quot;Dynamic Spool File
                                        Name&quot;</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.oracle.com/technetwork/issue-archive/2011/11-sep/o51plsql-453456.html"
                                        >PLSQL: Working with Strings</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://docs.oracle.com/cd/B10501_01/appdev.920/a96624/01_oview.htm#8423"
                                        >Overview of PL/SQL</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/1119710/how-do-i-get-the-current-year-using-sql-on-oracle"
                                        >How do I get the current year using SQL
                                        on Oracle? - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://www.oracle-dba-online.com/sql/date_operators_functions.htm"
                                        >Operating on Oracle Dates and Date
                                        Functions</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://blogs.oracle.com/opal/entry/using_php_oci8_2_0"
                                        >Using PHP and Oracle Database 12c
                                        Implicit Result Sets (Scripting and
                                        Oracle: Christopher Jones)</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://php.net/manual/en/ref.pdo-oci.php"
                                        >PHP: Oracle (PDO) - Manual</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>APIs</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a
                                        href="http://stackoverflow.com/questions/12547088/how-do-i-customize-facebooks-sharer-php"
                                        >How do I customize Facebook's
                                        sharer.php - Stack Overflow</a
                                    ></span
                                >
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <b><span>community</span></b>
                            </p>

                            <p class="link"><span>&nbsp;</span></p>

                            <p class="link">
                                <span
                                    ><a href="http://pareview.sh/pareview"
                                        >Review a drupal.org project online |
                                        pareview.sh</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/1975228"
                                        >Review bonus | Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://groups.drupal.org/node/427683"
                                        >PAReview: Review Template | Drupal
                                        Groups</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/node/707484"
                                        >Making a Drupal patch with Git |
                                        Drupal.org</a
                                    ></span
                                >
                            </p>

                            <p class="link">
                                <span
                                    ><a
                                        href="https://www.drupal.org/patch/apply"
                                        >Applying patches | Drupal.org</a
                                    ></span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Links',
}
</script>

<style scoped>
.title {
    text-align: center;
    padding: 0.25rem 0 0.25rem 0.5rem;
    font-weight: bold;
    font-size: 21px;
    color: #260000;
}
.links {
    cursor: pointer;
    text-decoration: none;
    color: #0b1445;
    margin: 0 0 2.5rem;
}
.link {
    color: #230572;
}
</style>
