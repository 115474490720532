<template>
    <div class="home">
        <div class="contentpanel">
            <div class="teasers">
                <div class="row teaserrow">
                    <div class="col teasercol">
                        <div class="title">
                            <h1>PC Rats, what's that?</h1>
                        </div>
                        <div class="about">
                            At some point I thought that may be a good name for
                            a tech firm, or simply a hole in the wall that fixes
                            computers and sells spare parts. This site was
                            originally created as flair for my resume when I
                            first graduated college. Since then I have added
                            things and rewrote the site on various frameworks.
                            However, I have been kept busy being employed and
                            the start-up idea hasn't taken off. I'm a software
                            developer working out of Tallahassee, FL. I develop
                            and maintain business applications over full stack.
                            I have interest in new language patterns and
                            improvements to code structure, along with data
                            storage and UI/UX optimizations and innovation. I
                            also enjoy studying algorithms and machine learning
                            methods. Although, I do find the possibilities with
                            modern front-end frameworks fascinating too. When
                            I'm not coding, I'm fixing something or spending
                            time in the sun enjoying nature.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
}
</script>

<style scoped>
.title {
    text-align: center;
    padding: 0.25rem 0 0.25rem 0.5rem;
    font-weight: bold;
    font-size: 21px;
    color: #260000;
}
.about {
    cursor: pointer;
    text-decoration: none;
    color: #0b1445;
    margin: 0 0 2.5rem;
}
</style>
