<template>
    <div class="home">
        <h2>Under Construction.</h2>
        <h3>
            In the meanwhile, feel free to view another page, or have fun with
            recursion below.
        </h3>
        <div class="contentpanel">
            <div
                id="boardblock"
                :style="{ width: widthX + 'px', height: widthY + 'px' }">
                <TilesGame
                    :widthX="gameWidth"
                    :widthY="gameHeigth"
                    :depth="0" />
            </div>
        </div>
    </div>
</template>

<script>
import TilesGame from '../components/TilesGame.vue'

export default {
    name: 'Home',
    components: {
        TilesGame,
    },
    computed: {
        gameWidth: function () {
            return Math.trunc(window.innerWidth * 0.8)
        },
        gameHeigth: function () {
            return Math.trunc(window.innerHeight * 0.6)
        },
    },
}
</script>
