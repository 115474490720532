<template>
    <div class="banner">
        <div class="bannerpic">
            <img src="../assets/img/banner.jpg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
}
</script>

<style scoped>
.banner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    max-width: 100%;
    max-height: 100%;
}
.bannerpic {
    order: 1;
    flex-grow: 1;
}


</style>
